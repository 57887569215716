import React from "react";
import { FcLineChart } from 'react-icons/fc';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';

const piIndexes = [
    {
        title: "USA Large Cap Growth & Income 50",
        description: "A US large-cap strategy that leverages our proprietary Phase Detection Engine (PDE) to identify high-momentum, high-dividend stocks in a diversified portfolio that balances growth and income goals.",
        goLiveDate: "June 30, 2023",
        geography: "North America",
        i1_link: "https://indexone.io/index/6f1b4325-a8fe-44b6-876e-3d4547236633-0/overview",
        returns: [
            { label: "2020", value: "37%" },
            { label: "2021", value: "31%" },
            { label: "2022", value: "-6%" },
            { label: "2023", value: "15%" },
            { label: "2024", value: "34%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "50" },
            { label: "DIVIDEND YIELD", value: "3.94%" },
            { label: "NASDAQ", value: "AVGO (US)" },
            { label: "NYSE", value: "KEY (US)" },
            { label: "NYSE", value: "CADE (US)" }
        ]
    },
    {
        title: "USA Large Cap Growth & Income 20",
        description: "A US large-cap strategy that leverages our proprietary Phase Detection Engine (PDE) to identify high-momentum, high-dividend stocks in a concentrated portfolio that balances growth and income goals.",
        goLiveDate: "June 15, 2024",
        geography: "North America",
        i1_link: "https://indexone.io/index/48380df4-f142-4594-9f05-5a6ef4e1f8de-0/overview",
        returns: [
            { label: "2020", value: "33%" },
            { label: "2021", value: "34%" },
            { label: "2022", value: "5%" },
            { label: "2023", value: "24%" },
            { label: "2024", value: "27%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "20" },
            { label: "DIVIDEND YIELD", value: "3.66%" },
            { label: "NASDAQ", value: "NVDA (US)" },
            { label: "NYSE", value: "UGI (US)" },
            { label: "NYSE", value: "FRT (US)" }
        ]
    },
    {
        title: "USA Growth-Income Switch 10",
        description: "A US strategy that switches between our concentrated US Growth & Income strategies using a Growth-Value switch-signal.",
        goLiveDate: "August 08, 2024",
        geography: "North America",
        i1_link: "https://indexone.io/index/10367c83-e450-4927-8055-ad13db9a2ca3-0/overview",
        returns: [
            { label: "2020", value: "60%" },
            { label: "2021", value: "75%" },
            { label: "2022", value: "20%" },
            { label: "2023", value: "44%" },
            { label: "2024", value: "18%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "SIGNAL" },
            { label: "HOLDINGS", value: "10" },
            { label: "DIVIDEND YIELD", value: "1.66%" },
            { label: "NASDAQ", value: "NVDA (US)" },
            { label: "NYSE", value: "T (US)" },
            { label: "NYSE", value: "CRM (US)" }
        ]
    },
    {
        title: "USA Large Cap Growth 50",
        description: "A US large-cap strategy that leverages our proprietary Phase Detection Engine (PDE) to identify high-momentum stocks in a diversified portfolio to achieve its growth objective.",
        goLiveDate: "June 30, 2023",
        geography: "North America",
        i1_link: "https://indexone.io/index/59faeb95-e07f-451c-a05c-efd6196663e3-0/overview",
        returns: [
            { label: "2020", value: "43%" },
            { label: "2021", value: "28%" },
            { label: "2022", value: "-10%" },
            { label: "2023", value: "16%" },
            { label: "2024", value: "38%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "50" },
            { label: "DIVIDEND YIELD", value: "1.71%" },
            { label: "NASDAQ", value: "VST (US)" },
            { label: "NASDAQ", value: "NTRA (US)" },
            { label: "NYSE", value: "NRG (US)" }
        ]
    },
    {
        title: "USA Large Cap Income 50",
        description: "A US long-term dividend strategy that leverages our proprietary Phase Detection Engine (PDE) to identify high-dividend growth & yield stocks in a diversified portfolio to achieve its income objective.",
        goLiveDate: "June 30, 2023",
        geography: "North America",
        i1_link: "https://indexone.io/index/6a804954-c292-4e6d-9aea-f0dd6dbc31fc-0/overview",
        returns: [
            { label: "2020", value: "12%" },
            { label: "2021", value: "33%" },
            { label: "2022", value: "4%" },
            { label: "2023", value: "9%" },
            { label: "2024", value: "20%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "50" },
            { label: "DIVIDEND YIELD", value: "5.70%" },
            { label: "NASDAQ", value: "AVGO (US)" },
            { label: "NYSE", value: "KEY (US)" },
            { label: "NYSE", value: "CADE (US)" }
        ]
    },
    {
        title: "USA Large Cap Growth 10",
        description: "A US large-cap strategy that leverages our proprietary Phase Detection Engine (PDE) to identify high-momentum stocks in a concentrated portfolio to achieve its growth objective.",
        goLiveDate: "August 08, 2024",
        geography: "North America",
        i1_link: "https://indexone.io/index/79ea74b7-7b1a-416a-b6fc-880dc8c45c8a-0/overview",
        returns: [
            { label: "2020", value: "60%" },
            { label: "2021", value: "35%" },
            { label: "2022", value: "-11%" },
            { label: "2023", value: "38%" },
            { label: "2024", value: "35%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "10" },
            { label: "DIVIDEND YIELD", value: "1.66%" },
            { label: "NASDAQ", value: "NVDA (US)" },
            { label: "NYSE", value: "T (US)" },
            { label: "NYSE", value: "CRM (US)" }
        ]
    },
    {
        title: "USA Large Cap Income 10",
        description: "A US extended-term dividend strategy that leverages our proprietary Phase Detection Engine (PDE) to identify high-dividend growth & yield stocks in a concentrated portfolio to achieve its income objective.",
        goLiveDate: "March 15, 2024",
        geography: "North America",
        i1_link: "https://indexone.io/index/6e64d4ba-7681-40d4-91f5-73e6f80d7778-0/overview",
        returns: [
            { label: "2020", value: "-2%" },
            { label: "2021", value: "35%" },
            { label: "2022", value: "20%" },
            { label: "2023", value: "10%" },
            { label: "2024", value: "16%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "50" },
            { label: "DIVIDEND YIELD", value: "5.76%" },
            { label: "NYSE", value: "UGI (US)" },
            { label: "NYSE", value: "FRT (US)" },
            { label: "NYSE", value: "POR (US)" }
        ]
    },
    {
        title: "USA SMID Growth 25",
        description: "A US small-mid cap strategy that leverages our proprietary Phase Detection Engine (PDE) to identify high-momentum stocks in a concentrated portfolio to achieve its growth objective.",
        goLiveDate: "November 23, 2023",
        geography: "North America",
        i1_link: "https://indexone.io/index/db68089d-5744-4c9f-b5ca-349eefadf97a-0/overview",
        returns: [
            { label: "2020", value: "49%" },
            { label: "2021", value: "23%" },
            { label: "2022", value: "-15%" },
            { label: "2023", value: "7%" },
            { label: "2024", value: "12%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "25" },
            { label: "DIVIDEND YIELD", value: "2.21%" },
            { label: "NYSE", value: "WRBY (US)" },
            { label: "NASDAQ", value: "PLMR (US)" },
            { label: "NYSE", value: "ENVA (US)" }
        ]
    },
    {
        title: "USA Large Cap Low Volatility 50",
        description: "A US large-cap strategy that leverages our proprietary Phase Detection Engine (PDE) to identify low-volatility stocks in a diversified portfolio to achieve its volatility objective.",
        goLiveDate: "October 31, 2023",
        geography: "North America",
        i1_link: "https://indexone.io/index/7e2721ba-1bd2-4257-adff-baea99662ebe-0/overview",
        returns: [
            { label: "2020", value: "20%" },
            { label: "2021", value: "27%" },
            { label: "2022", value: "-12%" },
            { label: "2023", value: "7%" },
            { label: "2024", value: "16%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "50" },
            { label: "DIVIDEND YIELD", value: "2.15%" },
            { label: "NYSE", value: "WMT (US)" },
            { label: "NYSE", value: "USFD (US)" },
            { label: "NYSE", value: "PKG (US)" }
        ]
    },
    {
        title: "USA Large Cap Size 50",
        description: "A US large-cap strategy that uses market capitalizsation to identify stocks in a diversified portfolio to achieve its objective of size-based exposure.",
        goLiveDate: "June 30, 2023",
        geography: "North America",
        i1_link: "https://indexone.io/index/e9ca4604-6f31-4041-bb6d-66774977bd76-0/overview",
        returns: [
            { label: "2020", value: "25%" },
            { label: "2021", value: "27%" },
            { label: "2022", value: "-20%" },
            { label: "2023", value: "30%" },
            { label: "2024", value: "33%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "50" },
            { label: "DIVIDEND YIELD", value: "1.74%" },
            { label: "NASDAQ", value: "GOOGL (US)" },
            { label: "NASDAQ", value: "NVDA (US)" },
            { label: "NASDAQ", value: "AMZN (US)" }
        ]
    },
    {
        title: "USA SMID Size 50",
        description: "A US small-mid cap strategy that uses market capitalizsation to identify stocks in a diversified portfolio to achieve its objective of size-based exposure.",
        goLiveDate: "November 06, 2023",
        geography: "North America",
        i1_link: "https://indexone.io/index/fd7390fc-6fae-4af2-b27b-7269ed4cdde3-0/overview",
        returns: [
            { label: "2020", value: "5%" },
            { label: "2021", value: "18%" },
            { label: "2022", value: "-10%" },
            { label: "2023", value: "19%" },
            { label: "2024", value: "3%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "50" },
            { label: "DIVIDEND YIELD", value: "3.02%" },
            { label: "NYSE", value: "TPR (US)" },
            { label: "NYSE", value: "EQH (US)" },
            { label: "NYSE", value: "WES (US)" }
        ]
    },
    {
        title: "USA Growth-Income Switch ETF",
        description: "A US strategy that switches between our ETF based US Growth & Income strategies using a Growth-Value switch-signal.",
        goLiveDate: "October 31, 2024",
        geography: "North America",
        i1_link: "https://indexone.io/index/ec998be0-5884-4634-9679-1412cdca8cb1-0/overview",
        returns: [
            { label: "2020", value: "21%" },
            { label: "2021", value: "44%" },
            { label: "2022", value: "-5%" },
            { label: "2023", value: "23%" },
            { label: "2024", value: "25%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "SIGNAL" },
            { label: "HOLDINGS", value: "2-7" },
            { label: "DIVIDEND YIELD", value: "n/a" },
            { label: "NYSE ARCA", value: "IVW (US)" }
        ]
    },
    {
        title: "All Country World xUS xEU Large Cap Growth 20",
        description: "A Global ex-US ex-Developed Europe strategy that leverages our proprietary Phase Detection Engine (PDE) to identify high-momentum stocks in a concentrated portfolio to achieve its growth objective.",
        goLiveDate: "July 31, 2024",
        geography: "Global",
        i1_link: "https://indexone.io/index/b2d842f8-3161-484a-82b7-ad18d9ea3730-0/overview",
        returns: [
            { label: "2020", value: "40%" },
            { label: "2021", value: "18%" },
            { label: "2022", value: "-4%" },
            { label: "2023", value: "32%" },
            { label: "2024", value: "27%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "20" },
            { label: "DIVIDEND YIELD", value: "2.03%" },
            { label: "TSX", value: "IMG (CA)" },
            { label: "ASX", value: "PNI (AU)" },
            { label: "TSE", value: "4540 (JP)" }
        ]
    },
    {
        title: "All Country World xUS Growth 10",
        description: "A Global ex-US strategy investing in only US-traded listings that leverages our proprietary Phase Detection Engine (PDE) to identify high-momentum stocks in a concentrated portfolio to achieve its growth objective.",
        goLiveDate: "June 17, 2024",
        geography: "Global",
        i1_link: "https://indexone.io/index/a72bcfab-7335-4d07-8d96-f76c109cac6d-0/overview",
        returns: [
            { label: "2020", value: "29%" },
            { label: "2021", value: "33%" },
            { label: "2022", value: "-17%" },
            { label: "2023", value: "33%" },
            { label: "2024", value: "20%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "10" },
            { label: "DIVIDEND YIELD", value: "2.65%" },
            { label: "NYSE", value: "CLS (CA)" },
            { label: "NASDAQ", value: "CCEP (GB)" },
            { label: "NASDAQ", value: "QFIN (CN)" }
        ]
    },
    {
        title: "Developed Europe Large Cap Growth 20",
        description: "A Developed Europe large-cap strategy that leverages our proprietary Phase Detection Engine (PDE) to identify high-momentum stocks in a concentrated portfolio to achieve its growth objective.",
        goLiveDate: "July 29, 2024",
        geography: "Europe",
        i1_link: "https://indexone.io/index/719818d5-b600-4e6e-a6ca-3e85eea343b6-0/overview",
        returns: [
            { label: "2020", value: "-1%" },
            { label: "2021", value: "27%" },
            { label: "2022", value: "-6%" },
            { label: "2023", value: "16%" },
            { label: "2024", value: "13%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "20" },
            { label: "DIVIDEND YIELD", value: "2.03%" },
            { label: "ENXT BR", value: "ARGX (BE)" },
            { label: "XETRA", value: "RHM (DE)" },
            { label: "XETRA", value: "SAP (DE)" }
        ]
    },
    {
        title: "Australia Large Cap Growth 20",
        description: "An Australia large-cap strategy that leverages our proprietary Phase Detection Engine (PDE) to identify high-momentum stocks in a concentrated portfolio to achieve its growth objective.",
        goLiveDate: "June 30, 2023",
        geography: "Asia-Pacific",
        i1_link: "https://indexone.io/index/443332aa-a0cd-44e6-b981-ff0e8509a067-0/overview",
        returns: [
            { label: "2020", value: "19%" },
            { label: "2021", value: "24%" },
            { label: "2022", value: "-10%" },
            { label: "2023", value: "8%" },
            { label: "2024", value: "23%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "20" },
            { label: "DIVIDEND YIELD", value: "1.61%" },
            { label: "ASX", value: "PME (AU)" },
            { label: "ASX", value: "JBH (AU)" },
            { label: "ASX", value: "ALL (AU)" }
        ]
    },
    {
        title: "India All Cap Growth 25",
        description: "An India all-cap strategy that leverages our proprietary Phase Detection Engine (PDE) to identify high-momentum stocks in a concentrated portfolio to achieve its growth objective.",
        goLiveDate: "March 15, 2024",
        geography: "Asia-Pacific",
        i1_link: "https://indexone.io/index/032a6255-e182-4c1d-aa2a-378037e65272-0/overview",
        returns: [
            { label: "2020", value: "36%" },
            { label: "2021", value: "28%" },
            { label: "2022", value: "-2%" },
            { label: "2023", value: "35%" },
            { label: "2024", value: "20%" }
        ],
        metrics: [
            { label: "REBALANCING", value: "QUARTERLY" },
            { label: "HOLDINGS", value: "25" },
            { label: "DIVIDEND YIELD", value: "0.60%" },
            { label: "NSE", value: "SUDARSCHEM (IN)" },
            { label: "NSE", value: "KIRLOSBROS (IN)" },
            { label: "NSE", value: "IIFLSEC (IN)" }
        ]
    }
];


function createSlide(index) {
    let slide = React.createElement('div', { className: "card" },
        React.createElement('h4', { className: "card-title" }, index.title,
            React.createElement('span', { className: "card-i1-link" },
                React.createElement('a', { href: index.i1_link, target: "_blank" }, <FcLineChart />)
            )
        ),
        React.createElement('span', { className: "card-geography" }, index.geography),
        React.createElement('span', { className: "card-go-live" }, "Live: ", index.goLiveDate),
        React.createElement('div', { className: "returns-grid" },
            React.createElement('div', { className: "return" },
                React.createElement('div', { className: "return-value" }, index.returns[0].value),
                React.createElement('div', { className: "return-label" }, index.returns[0].label)
            ),
            React.createElement('div', { className: "return" },
                React.createElement('div', { className: "return-value" }, index.returns[1].value),
                React.createElement('div', { className: "return-label" }, index.returns[1].label)
            ),
            React.createElement('div', { className: "return" },
                React.createElement('div', { className: "return-value" }, index.returns[2].value),
                React.createElement('div', { className: "return-label" }, index.returns[2].label)
            ),
            React.createElement('div', { className: "return" },
                React.createElement('div', { className: "return-value" }, index.returns[3].value),
                React.createElement('div', { className: "return-label" }, index.returns[3].label)
            ),
            React.createElement('div', { className: "return" },
                React.createElement('div', { className: "return-value" }, index.returns[4].value),
                React.createElement('div', { className: "return-label" }, index.returns[4].label)
            )
        ),
        React.createElement('p', { className: "card-description" }, index.description),
        React.createElement('div', { className: "metrics-grid" },
            React.createElement('div', { className: "metric" },
                React.createElement('div', { className: "metric-value" }, index.metrics[0].value),
                React.createElement('div', { className: "metric-label" }, index.metrics[0].label)
            ),
            React.createElement('div', { className: "metric" },
                React.createElement('div', { className: "metric-value" }, index.metrics[1].value),
                React.createElement('div', { className: "metric-label" }, index.metrics[1].label)
            ),
            React.createElement('div', { className: "metric" },
                React.createElement('div', { className: "metric-value" }, index.metrics[2].value),
                React.createElement('div', { className: "metric-label" }, index.metrics[2].label)
            ),

            index.metrics.length > 3 ? React.createElement('div', { className: "metric" },
                React.createElement('div', { className: "metric-value" }, index.metrics[3].value),
                React.createElement('div', { className: "metric-label" }, index.metrics[3].label)
            ) : React.createElement('div'),
            index.metrics.length > 4 ? React.createElement('div', { className: "metric" },
                React.createElement('div', { className: "metric-value" }, index.metrics[4].value),
                React.createElement('div', { className: "metric-label" }, index.metrics[4].label)
            ) : React.createElement('div'),
            index.metrics.length > 5 ? React.createElement('div', { className: "metric" },
                React.createElement('div', { className: "metric-value" }, index.metrics[5].value),
                React.createElement('div', { className: "metric-label" }, index.metrics[5].label)
            ) : React.createElement('div')
        )
    );

    return slide;
}

function SwiperSlides() {
    return (
        <>
            <SwiperSlide>{createSlide(piIndexes[0])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[1])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[2])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[3])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[4])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[5])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[6])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[7])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[8])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[9])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[10])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[11])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[12])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[13])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[14])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[15])}</SwiperSlide>
            <SwiperSlide>{createSlide(piIndexes[16])}</SwiperSlide>
        </>
    );
}

function SwiperSlider() {
    const widthResolution = window.screen.width;
    const isNav = widthResolution > 768 ? true : false;
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                breakpoints={{
                    240: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        navigation: {
                            enabled: false,
                            visibility: false,
                        },
                    },
                    768: {
                        slidesPerView: 1.2,
                        spaceBetween: 90,
                        navigation: {
                            enabled: true,
                            visibility: true,
                        },
                    },
                }}
                centeredSlides={true}
                loop={true}
                pagination={{
                    type: 'fraction',
                }}
                navigation={isNav}
                modules={[Pagination, Navigation]}
                className="my-swiper-wrapper"
            >
                {SwiperSlides()}
            </Swiper >
        </>
    );
}

export default SwiperSlider;
