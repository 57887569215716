import React, { useEffect, useRef, useState } from 'react';
import { SectionsContainer, Section } from 'react-fullpage';
import { SocialIcon } from 'react-social-icons';

import './App.css';

import SwiperSlider from './components/SwiperSlider';

const App = () => {
  const options = {
    scrollingSpeed: 500,
    navigation: true,
    anchors: ['about', 'rwa', 'catalogue', 'features', 'contacts'],
    verticalCentered: true,
    // fitToSection: true,
    fitToSection: false,
  };

  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // videoElement.currentTime = 0;
          // Play video from the beginning when it becomes visible
          videoElement.play();
        } else {
          // Pause video when it is not visible
          // videoElement.pause();
          videoElement.currentTime = 0;
        }
      },
      { threshold: 0.01 }
    );

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
  }, []);

  const sectionContactsRef = useRef(null);
  const [isLogoDuplicated, SetIsLogoDuplicated] = useState(false);

  useEffect(() => {
    const sectionContactsElement = sectionContactsRef.current;

    if (sectionContactsElement) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            SetIsLogoDuplicated(true);
          } else {
            SetIsLogoDuplicated(false);
          }
        },
        { threshold: 0.1 }
      );

      observer.observe(sectionContactsElement);

      return () => {
        if (sectionContactsElement) {
          observer.unobserve(sectionContactsElement);
        }
      };
    }
  }, []);

  return (
    <div>
      <header>
        <div className={`logo-wrapper ${isLogoDuplicated ? 'logo-hidden' : ''}`}>
          <img src='./images/logo.png' alt='phaseinvest' className='logo-img' />
        </div>
        <div className='menu-wrapper'>
          <SocialIcon url='https://t.me/phaseinvest' style={{ width: '28px', height: '28px', marginRight: '6px' }} />
          <SocialIcon url='https://www.linkedin.com/company/phaseinvest' style={{ width: '30px', height: '30px', marginRight: '6px' }} />
        </div>
      </header>

      <SectionsContainer {...options}>
        <Section key='1' className='section section-about'>
          <div className='about-badge-wrapper'>
            <div className='about-badge'>
              equity on&#8209;chain
            </div>
          </div>
          <div className='about-wrapper'>
            <div className='about-message'>
              <div className='about-key-message'>
                Expand Your Crypto Horizons
              </div>
              <div className='about-key-message-details'>
                Welcome to the home page of RWAs that broaden the Web3 product range.
              </div>
              <div className='about-key-message-details'>
                phaseinvest offers improved control over the risk&#8209;return profile of your crypto portfolio.
              </div>
            </div>
            <div className='about-image'></div>
          </div>
        </Section>
        <Section key='2' className='section section-product'>
          <div className='product-badge-wrapper'>
            <div className='product-badge'>
              model&#8209;driven
            </div>
          </div>
          <div className='product-wrapper'>
            <div className='product-message'>
              <div className='product-key-message'>
                Tokens backed by Actively&#8209;Managed Equity Portfolios
              </div>
              <div className='product-key-message-details'>
                Expertly curated strategies that adapt to market conditions through dynamic portfolio management, aiming to outperform their respective market benchmarks.
              </div>
              <div className='product-key-message-details'>
                phaseinvest builds portfolios using proprietary expertise to select stocks that we believe will yield superior returns.
              </div>
            </div>
            <div className='product-image'></div>
          </div>
        </Section>
        <Section key='3' className='section section-catalogue'>
          <div className='catalogue-badge-wrapper'>
            <div className='catalogue-badge'>
              your choice
            </div>
          </div>
          <div className='catalogue-wrapper'>
            <div className='catalogue-message'>
              <div className='catalogue-key-message'>
                RWA Catalogue
              </div>
            </div>
            <div className='catalogue-image'></div>
          </div>
          <div className='catalogue-wrapper'>
            <div className='rwa-slider-wrapper'>

              <SwiperSlider />

            </div>
          </div>
        </Section>
        <Section key='4' className='section section-features'>
          <div className='features-badge-wrapper'>
            <div className='features-badge'>
              takes you higher
            </div>
          </div>
          <div className='features-wrapper'>
            <div className='features-image'></div>
            <div className='features-message'>
              <div className='features-key-message'>
                RWA Features
              </div>
              <div className='features-key-message-details'>
                Alpha&#8209;generating
                <div className='features-key-message-details-2'>
                  above TradFi benchmark returns
                </div>
              </div>
              <div className='features-key-message-details'>
                Risk&#8209;diversifying
                <div className='features-key-message-details-2'>
                  low correlation with most crypto currencies
                </div>
              </div>
              <div className='features-key-message-details'>
                Accessible & Affordable
                <div className='features-key-message-details-2'>
                  low minimum investment
                </div>
              </div>
              <div className='features-key-message-details'>
                Transparent & Secure
                <div className='features-key-message-details-2'>
                  off-chain audited & 100% backed
                </div>
              </div>
            </div>
          </div>
          <video
            ref={videoRef}
            muted
            className="bg-video"
            autoPlay
            playsInline // Ensures autoplay works on mobile
          >
            <source src="images/bg-features.mp4" type="video/mp4" />
            {/* <source src="images/bg-features.webm" type="video/webm" /> */}
            Your browser does not support the video tag.
          </video>
        </Section>
        <Section key='5' className='section section-contacts'>
          <div className='contacts-badge-wrapper'>
            <div className='contacts-badge'>
              let's talk!
            </div>
          </div>
          <div className='contacts-wrapper'>
            <div className='contacts-follow-us'>
              <img src='./images/pi-logo.svg' alt='phaseinvest' className='contacts-logo-img' ref={sectionContactsRef} />
            </div>
            <div className='contacts-contact-us'>
              <div className='contacts-person'>
                <div className='contacts-name'>
                  Arun Soni, CEO
                </div>
                <div className="contacts-phone">
                  <img src="./icons/flag-de.png" alt='DE' className="contacts-flag" />
                  <a href="tel:+49 30 5528 4699">+49 30 5528 4699</a>
                </div>
                <div className='contacts-social'>
                  <SocialIcon url='mailto:arun.soni@phaseinvest.com' style={{ width: '30px', height: '30px', marginRight: '6px' }} />
                  <SocialIcon url='https://t.me/soni_arun' style={{ width: '28px', height: '28px', marginRight: '6px' }} />
                  <SocialIcon url='https://www.linkedin.com/in/arun-soni-1a768b16' style={{ width: '30px', height: '30px', marginRight: '6px' }} />
                </div>
              </div>
              <div className='contacts-person'>
                <div className='contacts-name'>
                  Oleksii Zagidullin, CTO
                </div>
                <div className="contacts-phone">
                  <img src="./icons/flag-ua.png" alt='UA' className="contacts-flag" />
                  <a href="tel:+38 068 857 2653">+38 068 857 2653</a>
                </div>
                <div className='contacts-social'>
                  <SocialIcon url='mailto:oleksii.zagidullin@phaseinvest.com' style={{ width: '30px', height: '30px', marginRight: '6px' }} />
                  <SocialIcon url='https://t.me/ozager' style={{ width: '28px', height: '28px', marginRight: '6px' }} />
                  <SocialIcon url='https://www.linkedin.com/in/oleksiizagidullin' style={{ width: '30px', height: '30px', marginRight: '6px' }} />
                </div>
              </div>
            </div>
          </div>
        </Section>
      </SectionsContainer>
    </div >
  );
};

export default App;
